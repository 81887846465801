import { isNud } from '@/utilities/typing';
import Fields from '@/models/services/fields.json';
import LayoutType from '@/models/enums/layout-type.js';


/**
 * エクスポート定義 定義項目サービス
 */
class FieldControlService
{
    //定義値
    static SUFFIX_REQUIRED_CAN_VIEW_PRODUCTION = 'after--red'; // 製作情報閲覧権限が必要な項目のフロントCSS用定義文字列

    //全てのfieldsを返す
    static all() {
        return Fields;
    }

    //field_keyをもとにオブジェクトを返す
    static get(field_key) {
        let field = Fields.find(field => field.field_key === field_key);

        if (isNud(field)) {
            return null;
        }

        return field;
    }

    //field_keys[配列]をもとにオブジェクトの配列を返す
    static getFields(field_keys) {
        let fields = Fields.filter(field => field_keys.includes(field.field_key));

        if (isNud(fields)) {
            return [];
        }

        return fields;
    }

    //fieldsのkey => labelの配列を返す
    static options() {
        let array = [];
        for (let field of Fields) {
            array.push({key: field.field_key, label: field.field_name});
        }

        return array;
    }

    //製品グループ情報のfieldsのkey => labelの配列を返す
    static optionsOfProductGroup() {
        let array = [];
        for (let field of Fields) {
            let suffix = '';
            //製作情報閲覧権限が必要な項目の場合
            if (field.field_required_can_view_production) {
                suffix = this.SUFFIX_REQUIRED_CAN_VIEW_PRODUCTION;
            }
            if (field.field_group === 'product_group') {
                array.push({key: field.field_key, label: field.field_name, suffix: suffix});
            }
        }

        return array;
    }

    //製品サブグループ情報のfieldsのkey => labelの配列を返す
    static optionsOfProductSubGroup() {
        let array = [];
        for (let field of Fields) {
            let suffix = '';
            //製作情報閲覧権限が必要な項目の場合
            if (field.field_required_can_view_production) {
                suffix = this.SUFFIX_REQUIRED_CAN_VIEW_PRODUCTION;
            }
            if (field.field_group === 'product_subgroup') {
                array.push({key: field.field_key, label: field.field_name, suffix: suffix});
            }
        }

        return array;
    }

    //製品情報のfieldsのkey => labelの配列を返す
    static optionsOfProduct() {
        let array = [];
        for (let field of Fields) {
            let suffix = '';
            //製作情報閲覧権限が必要な項目の場合
            if (field.field_required_can_view_production) {
                suffix = this.SUFFIX_REQUIRED_CAN_VIEW_PRODUCTION;
            }
            if (field.field_group === 'product') {
                array.push({key: field.field_key, label: field.field_name, suffix: suffix});
            }
        }

        return array;
    }

    //商社別情報のfieldsのkey => labelの配列を返す
    static optionsOfProductRetailer() {
        let array = [];
        for (let field of Fields) {
            let suffix = '';
            //製作情報閲覧権限が必要な項目の場合
            if (field.field_required_can_view_production) {
                suffix = this.SUFFIX_REQUIRED_CAN_VIEW_PRODUCTION;
            }
            if (field.field_group === 'product_retailer') {
                array.push({key: field.field_key, label: field.field_name, suffix: suffix});
            }
        }

        return array;
    }

    //親製品情報のfieldsのkey => labelの配列を返す
    static optionsOfParentProduct() {
        let array = [];
        for (let field of Fields) {
            let suffix = '';
            //製作情報閲覧権限が必要な項目の場合
            if (field.field_required_can_view_production) {
                suffix = this.SUFFIX_REQUIRED_CAN_VIEW_PRODUCTION;
            }
            if (field.field_group === 'parent_product') {
                array.push({key: field.field_key, label: field.field_name, suffix: suffix});
            }
        }

        return array;
    }

    /**
     * 製品グループ情報を表示するか
     *
     * @param {*} layout_type
     * @returns
     */
    static is_shown_product_group(layout_type) {
        if (layout_type) {
            return true;
        }
        return false;
    }

    /**
     * 製品サブグループ情報を表示するか
     *
     * @param {*} layout_type
     * @returns
     */
    static is_shown_product_subgroup(layout_type) {
        if (layout_type && layout_type !== LayoutType.PRODUCT_GROUP) {
            return true;
        }
        return false;
    }

    /**
     * 製品情報を表示するか
     *
     * @param {*} layout_type
     * @returns
     */
    static is_shown_product(layout_type) {
        if (
            layout_type === LayoutType.PRODUCT ||
            layout_type === LayoutType.PRODUCT_RETAILER ||
            layout_type === LayoutType.COMPONENT

        ) {
            return true;
        }
        return false;
    }

    /**
     * 商社別情報を表示するか
     *
     * @param {*} layout_type
     * @returns
     */
    static is_shown_product_retailer(layout_type) {
        if (layout_type === LayoutType.PRODUCT_RETAILER) {
            return true;
        }
        return false;
    }

    /**
     * 親製品情報を表示するか
     *
     * @param {*} layout_type
     * @returns
     */
    static is_shown_parent_product(layout_type) {
        if (layout_type === LayoutType.COMPONENT) {
            return true;
        }
        return false;
    }

    /**
     * 選択されたfieldsのうち、選択された定義タイプに一致しないfieldは削除する
     *
     * @param {*} fields
     * @param {*} layout_type
     * @returns
     */
    static filterCheckedFieldsByLayoutType(export_layout) {
        let delete_fields = [];

        if (export_layout.layout_type === LayoutType.PRODUCT_GROUP) {
            //消すもの：製品サブ・製品・商社別・親製品
            delete_fields = Fields.filter(field => field.field_group !== 'product_group' );
        }
        if (export_layout.layout_type === LayoutType.SUBGROUP) {
            //消すもの：製品・商社別・親製品
            delete_fields = Fields.filter(field =>
                field.field_group === 'product' ||
                field.field_group === 'product_retailer' ||
                field.field_group === 'parent_product'
            );
        }
        if (export_layout.layout_type === LayoutType.PRODUCT) {
            //消すもの：商社別・親製品
            delete_fields = Fields.filter(field =>
                field.field_group === 'product_retailer' ||
                field.field_group === 'parent_product'
            );
        }
        if (export_layout.layout_type === LayoutType.PRODUCT_RETAILER) {
            //消すもの：親製品
            delete_fields = Fields.filter(field => field.field_group === 'parent_product');
        }
        if (export_layout.layout_type === LayoutType.PARENT_PRODUCT) {
            //消すもの：商社別
            delete_fields = Fields.filter(field => field.field_group === 'product_retailer');
        }

        // 送る値から削除する
        for (let field of delete_fields) {
            let index = export_layout.details.findIndex(detail => detail.field_key === field.field_key);
            if (index > -1) {
                export_layout.details.splice(index, 1);
            }
        }
    }
}

export default FieldControlService;